.card {
	&.card-border-heavy {
		border-width: 5px;
	}

	&.card-transparent {
		background: none;

		.card-header {
			background: none;
		}
	}
}