.table {
	th {
		font-size: 0.85em;
	}

	.agreement-table-current-version-column {
		background-color: #00d5ff15;

		&:hover {
			background-color: darken(#00d5ff15, 5%);
		}
	}

	tr.selected {
		font-weight: bold;
	}

	tr.archived {
		background-color:  #00000010;
		filter: grayscale(100%);

		&:hover {
			background-color: #00000020;
		}
	}
}

.agreement-table,
.branch-table {
	// Prevent the sort indicators from causing line wrap:
	th {
		white-space: nowrap;
	}

	// Styling for toggling sort indicators. We're combining display and
	// visibility to prevent popping as the indicators appear and disappear:
	th {
		.fa.fa-caret-up {
			display: none;
		}

		.fa.fa-caret-down {
			visibility: hidden;
		}

		&[role=columnheader]:not(.no-sort) {
			cursor: pointer;
		}

		&[aria-sort=descending]:not(.no-sort) {
			.fa.fa-caret-up {
				display: inline-block;
			}
			.fa.fa-caret-down {
				display: none
			}
		}

		&[aria-sort=ascending]:not(.no-sort) {
			.fa.fa-caret-down {
				visibility: visible;
			}
		}
	}
}
