@import "colors";
@import "bootstrap_variables";
@import "bootstrap/scss/bootstrap";

@import "table";
@import "animate.css/animate.css";
@import "hint.css/hint.css";
@import "card";

html {
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}


// Collapse-buttons
.collapse-button {
	.fa-angle-up {
		display: inline-block !important;
	}
	.fa-angle-down {
		display: none !important;
	}
	&.collapsed {
		.fa-angle-up {
			display: none !important;
		}
		.fa-angle-down {
			display: inline-block !important;
		}
	}
}

label {
	font-weight: bold;
}

// Agreement form
.agreement-form-nav {
	.nav-link {
		padding: 0.5rem 1rem;
		border: 1px solid rgba(0,0,0,0.2);
		margin-right: -1px;

		&:hover,
		&:focus {
			border-color: rgba(0,0,0,0.8);
		}

	}
}
@media screen and (max-width: 768px) {
	.agreement-form-nav {
		.nav-link {
			padding: 0.5rem 0.5rem;
			font-size: 1.1rem;
		}
	}
}

.agreement-current-files {
	tr {
		&:first-child {
			td {
				border-top: 0;
			}
		}
	}
}

// Hide vich-file checkboxes
.vich-file {
	.checkbox {
		display: none;
	}
}

.table {
	td, th {
		vertical-align: middle;
	}
}

// Full width element
.fullwidth {
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
}

// Error in forms
.has-error {
	.help-block {
		color: red;
	}
}

// Notifications
.notify {
	display: none;
}

// Sticky
.sticky {
	&.stuck {
		margin: 0px !important;
    	background-color: #fff;
    	padding: 10px;
    	border-bottom: 1px solid #ccc;
	}
}

// circle
.circle{
	width: 100px;
	height: 100px;
	border-radius: 50%;
	font-size: 2em;
	color: #fff;
	line-height: 100px;
	text-align: center;
	display: inline-block;

	&.small {
		font-size: 1em;
		width: 50px;
		height: 50px;
		line-height: 50px;
	}

	&.ha {
		background: $cyan;
	}
	&.hta {
		background: $orange;
	}
	&.sa {
		background: $teal;
	}
}

.ff-breadcrumb {
	.active {
		color: $fagforbundet-text-color;
	}
}

// Version-view
.agreement-versions-table {
	.card-body {
		> div {
			border-bottom: 1px solid #eee;
		}
	}
}


.branch-list-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));

	.card-branch {
		.card-branch-body {
			min-width: 0;

			.card-branch-name-container {
				height: 45px;
				line-height: 45px;

				h2 {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					a {
						color: #3c3c3b;
						text-decoration: underline;

						&:active, &:focus, &:hover {
							color: #fc3729;
						}
					}
				}
			}
		}

		.card-branch-edit, .card-branch-delete, .card-branch-confirm, .card-branch-cancel {
			&:active, &:focus, &:hover {
				color: #fc3729;
			}
		}
	}

	.dropdown-menu {
		background: transparent;
		border: 0;
		min-width: 0;

		&.show {
			display: flex;
			flex-direction: column;
		}
	}
}

header {
	.btn-link {
		color: #3c3c3b;
		padding: 0.25rem 0.5rem;
		border-radius: 0;
	}

	.btn-link:hover {
		background-color: #fc3729;
		color: #fff;
		text-decoration: none;
	}
}
