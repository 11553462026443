// Color overrides
$red: $fagforbundet-red;
$blue:    #007bff;
$primary: $red;

// Options
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       false;

// Spacing
$spacer: 1rem;
$spacers: (
   0: 0,
   1: ($spacer * .25),
   2: ($spacer * .5),
   3: $spacer,
   4: ($spacer * 1.5),
   5: ($spacer * 3),
   6: ($spacer * 3.25),
   7: ($spacer * 3.5),
   8: ($spacer * 3.75),
   9: ($spacer * 4),
   10: ($spacer * 4.5),
   11: ($spacer * 5),
   12: ($spacer * 5.5),
   13: ($spacer * 6),
   14: ($spacer * 6.5)
 );

// Body
$body-bg: $fagforbundet-paper-white;
$body-color: $fagforbundet-text-color;

// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1060px,
	xl: 1200px
);

// Components
$component-active-color: #FFF;
$component-active-bg: $blue;

// Fonts
$font-family-sans-serif: 'Source Sans Pro', sans-serif;

$font-size-base: (18px / 16px) * 1rem; // Assumes the browser default, typically `16px`

$font-weight-bold: 700;

$font-size-h1: (38px / 16px) * 1rem;
$font-size-h2: (24px / 16px) * 1rem;
$font-size-h3: (20px / 16px) * 1rem;
$font-size-h4: $font-size-base;

$headings-font-weight: 700;

$display1-size: 4rem;
$display2-size: 3.5rem;
$display3-size: 3rem;
$display4-size: 2.5rem;

$display1-weight: 600;
$display2-weight: 600;
$display3-weight: 600;
$display4-weight: 600;

$lead-font-size: (20px / 16px) * 1rem;
$lead-font-weight: 400;

// Buttons
$btn-padding-x:                  1.2rem;
$btn-padding-y:                  0.3rem;

$btn-font-weight:                600;

// Forms
$custom-control-disabled-indicator-bg: $fagforbundet-paper-white;

// Navs
$nav-link-padding: 0 1em;
$nav-tabs-border-color: #3c3c3b; // #ddd
$nav-tabs-active-link-hover-border-color:  #3c3c3b;

// Breadcrumbs
$breadcrumb-padding-x: 0;
$breadcrumb-bg: transparent;
